import { APP_INITIALIZER, Provider } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Logger } from '../services/logger.service';

/**
 * Create a script element in DOM and initiate Cookie script.
 */
export function HeurekaWidgetScriptInitializer(document: Document, logger: Logger) {
  return async () => {
    if (!document) {
      logger.error('Document interface unavailable. Cookie script works in browser only.');
      return;
    }

    const widgetScript: HTMLScriptElement = document.createElement('script');
    widgetScript.type = 'text/javascript';
    widgetScript.async = true;
    widgetScript.src = 'https://cz.im9.cz/direct/i/gjs.php?n=wdgt&sak=35510177A2FCA02DBE635CD981BDD392';

    const head: HTMLHeadElement = document.getElementsByTagName('head')[0];
    head.prepend(widgetScript);
  };
}

export const heurekaWidgetScriptInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: HeurekaWidgetScriptInitializer,
  deps: [
    DOCUMENT,
    Logger
  ]
};
