import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { Logger } from '../../../core/services/logger.service';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'raf-heureka-widget',
  templateUrl: './heureka-widget.component.html',
  styleUrls: ['./heureka-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeurekaWidgetComponent implements AfterViewInit {
  @Input()
  set size(widgetSize: WidgetSize) {
    if (widgetSize === 'large') {
      this.widgetSize$.next('3');
    } else if (widgetSize === 'medium') {
      this.widgetSize$.next('2');
    } else {
      this.widgetSize$.next('1');
    }
  }

  widgetSize$: BehaviorSubject<string> = new BehaviorSubject('1');

  constructor(@Inject(DOCUMENT) private document: Document, private logger: Logger) {
  }

  ngAfterViewInit(): void {
    this.initHeurekaWidget();
  }

  initHeurekaWidget(): void {
    if (!environment?.heurekaWidgetKey) {
      this.logger.log('Heureka widget key is not set. Skipping initialization.');
      return;
    }

    this.logger.log('Heureka widget initialized');
    const _hwq = (window as any)['_hwq'] || [];
    _hwq.push(['setKey', environment.heurekaWidgetKey]);
    _hwq.push(['showWidget', this.widgetSize$.getValue(), '92092', 'Plano', 'plano-cz']);
  }
}

export type WidgetSize = 'small' | 'medium' | 'large';
